@font-face {
  font-family: 'InterLight';
  src: url('assets/fonts/Inter-Light.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'InterRegular';
  src: url('assets/fonts/Inter-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InterMedium';
  src: url('assets/fonts/Inter-Medium.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'InterBold';
  src: url('assets/fonts/Inter-Bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

